import * as React from "react"

import Layout from "../components/layout/layout"
import HeroSectionNew from "../components/sections/hero-section-new"
import Seo from "../components/seo"
import PageWordPressListSectionUpdateNew from "../components/sections/page-wordpress-list-section-update-new"

const WordPressPage = () => {
  return (
    <Layout>
      <Seo title="Best WordPress Themes" />

      <HeroSectionNew subTitle="Best WordPress Themes" />

      <PageWordPressListSectionUpdateNew />
    </Layout>
  )
}

export default WordPressPage
