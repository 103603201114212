import React from "react"
import { Link } from "gatsby"

const HeroSectionNew = ({ subTitle }) => (
  <div className="bg-gray-100 flex justify-center items-center px-4 pt-20 md:pt-32 pb-12">
    <div className='w-full max-w-screen-2xl flex flex-col md:flex-row items-center md:space-x-6 mx-auto'>
      <div className='w-full md:w-11/12 flex flex-col items-center justify-center mx-auto'>
        <h1 className='text-4xl md:text-5xl font-medium text-gray-900 text-center uppercase leading-tight mt-6'>
          Find The Best Theme & Template For Your Next Site
        </h1>

        <h2 className='w-full md:w-6/12 md:text-lg text-gray-900 text-base text-center mt-6'>The best curated directory of the best website themes and templates</h2>

        <div className="w-full flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8 mt-12 md:mt-24">
          <Link to="/" activeClassName="active" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">All Themes & Templates</Link>
          <Link to="/wordpress/" activeClassName="active" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">WordPress</Link>
          <Link to="/squarespace/" activeClassName="active" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">Squarespace</Link>
          <Link to="/shopify/" activeClassName="active" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">Shopify</Link>
        </div>

        <div className="mt-12 border-b-2">
          <h2 className="text-gray-900 text-3xl font-semibold text-center md:text-4xl">{subTitle}</h2>
        </div>
      </div>
    </div>
  </div>
)

export default HeroSectionNew